import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { PrismicLink } from "@prismicio/react"

import { CTA_TEXT_DEFAULT } from "../../constants"

const CTACardsSection = ({ cta_cards }) => {
  if (!cta_cards.length) return null

  return (
    <div className="grid grid-cols-1 md:grid-cols-3">
      {cta_cards.map((item, index) => {
        const { image, title, cta_text, cta_link } = item.cta_card.document.data

        if (!image || !title || !cta_link) return null

        return (
          <PrismicLink
            key={`${title}-${index}`}
            className="group relative"
            field={cta_link}
          >
            <div className="aspect-h-1 aspect-w-1 h-full w-full">
              <GatsbyImage
                className="w-full group-hover:opacity-90 group-focus:opacity-90"
                image={getImage(image.gatsbyImageData)}
                aspectRatio={1 / 1}
                alt={image.alt || ""}
                style={{ position: "absolute" }} // Override Gatsby styling
              />
            </div>
            <div className="absolute top-0 left-0 m-gutter">
              <h4 className="max-w-xs text-3xl font-bold uppercase text-cloud-white md:text-2xl lg:text-3xl">
                {title}
              </h4>
            </div>
            <div className="absolute bottom-0 left-0 m-gutter">
              <div className="button">{cta_text || CTA_TEXT_DEFAULT}</div>
            </div>
          </PrismicLink>
        )
      })}
    </div>
  )
}

export default CTACardsSection
