import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { PrismicRichText } from "@prismicio/react"

import AccordionItem from "../AccordionItem/AccordionItem"

const ProfilesSection = ({ fields, profiles }) => {
  const { title, content } = fields

  if (!profiles.length) return null

  return (
    <div className="section">
      {title && (
        <div className="grid grid-cols-12 lg:gap-10">
          <div className="col-start-2 col-end-13 mb-12 lg:mb-14">
            <h2 className="text-3xl font-bold uppercase tracking-wide">
              {title}
            </h2>
            {content && (
              <div className="rte mt-4 max-w-3xl text-xl">
                <PrismicRichText field={content.richText} />
              </div>
            )}
          </div>
        </div>
      )}

      <div className="grid grid-cols-12 lg:gap-10">
        <div className="col-start-2 col-end-12">
          <div className="grid grid-cols-10 gap-y-10 lg:gap-x-10">
            {profiles.map((item, index) => {
              const { image, name, position, bio } = item.profile.document.data

              if (!name) return null

              const profileCardContent = (
                <>
                  <div className="aspect-w-2 aspect-h-3">
                    <GatsbyImage
                      image={getImage(image.gatsbyImageData)}
                      alt={image.alt || ""}
                      aspectRatio={2 / 3}
                      style={{ position: "absolute" }} // Override Gatsby styling
                    />
                  </div>
                  <div className="mt-4">
                    <h4 className="text-xl font-semibold">{name}</h4>
                    {position && <div className="mt-0.5">{position}</div>}
                    <div className="link link--black mt-2 font-semibold">
                      Read biography
                    </div>
                  </div>
                </>
              )

              return bio ? (
                <AccordionItem
                  className="col-span-10 lg:col-span-2"
                  key={index}
                >
                  <AccordionItem.Toggle className="cursor-pointer">
                    {profileCardContent}
                  </AccordionItem.Toggle>
                  <AccordionItem.Content className="rte mt-4 text-sm">
                    <PrismicRichText field={bio.richText} />
                  </AccordionItem.Content>
                </AccordionItem>
              ) : (
                <div className="col-span-10 lg:col-span-2" key={index}>
                  {profileCardContent}
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProfilesSection
