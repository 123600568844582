import React, { useState } from "react"

const AnnouncementsSection = ({ fields }) => {
  const { title } = fields

  const [isActive, setActive] = useState("ASX")

  const toggleSymbol = symbol => {
    setActive(symbol)
  }

  return (
    <div>
      {title && (
        <div className="mb-10">
          <h2 className="text-3xl font-bold uppercase tracking-wide">
            {title}
          </h2>
        </div>
      )}
      <div>
        <div className="mb-10 inline-block rounded-sm border">
          <button
            className={`border-r px-4 py-1 ${
              isActive === "ASX" ? "bg-night-black text-cloud-white" : ""
            }`}
            onClick={() => toggleSymbol("ASX")}
          >
            ASX
          </button>
          <button
            className={`px-4 py-1 ${
              isActive === "NZX" ? "bg-night-black text-cloud-white" : ""
            }`}
            onClick={() => toggleSymbol("NZX")}
          >
            NZX
          </button>
        </div>

        <div className={isActive === "ASX" ? "block" : "hidden"}>
          <div data-yourir="announcements pageSize=10 symbol=kmd.asx">
            <div className="space-x-1">
              <button
                className="inline-block rounded-sm border py-1 px-2 text-sm"
                data-yourir="firstYear"
              >
                &lt;&lt;
              </button>
              <button
                className="inline-block rounded-sm border py-1 px-2 text-sm"
                data-yourir="prevYear"
              >
                &lt;
              </button>
              <button
                className="inline-block rounded-sm border py-1 px-2 text-sm"
                data-yourir="navYear1"
              ></button>
              <button
                className="inline-block rounded-sm border py-1 px-2 text-sm"
                data-yourir="navYear2"
              ></button>
              <button
                className="inline-block rounded-sm border py-1 px-2 text-sm"
                data-yourir="navYear3"
              ></button>
              <button
                className="inline-block rounded-sm border py-1 px-2 text-sm"
                data-yourir="navYear4"
              ></button>
              <button
                className="inline-block rounded-sm border py-1 px-2 text-sm"
                data-yourir="navYear5"
              ></button>
              <button
                className="inline-block rounded-sm border py-1 px-2 text-sm"
                data-yourir="nextYear"
              >
                &gt;
              </button>
              <button
                className="inline-block rounded-sm border py-1 px-2 text-sm"
                data-yourir="lastYear"
              >
                &gt;&gt;
              </button>
            </div>

            <div className="mt-10" data-yourir="ifnot isEmpty">
              <ul className="space-y-4" data-yourir="items">
                <li>
                  <div className="mb-1" data-yourir="$cur.date"></div>
                  <div
                    className="hidden-link text-2xl font-semibold"
                    data-yourir="$cur.heading"
                  ></div>
                </li>
              </ul>
            </div>

            <div data-yourir="ifnot isEmpty">
              <div className="mt-10 space-x-1">
                <button
                  className="inline-block rounded-sm border py-1 px-2 text-sm"
                  data-yourir="firstPage"
                >
                  &lt;&lt;
                </button>
                <button
                  className="inline-block rounded-sm border py-1 px-2 text-sm"
                  data-yourir="prevPage"
                >
                  &lt;
                </button>
                <button
                  className="inline-block rounded-sm border py-1 px-2 text-sm"
                  data-yourir="navPage1"
                ></button>
                <button
                  className="inline-block rounded-sm border py-1 px-2 text-sm"
                  data-yourir="navPage2"
                ></button>
                <button
                  className="inline-block rounded-sm border py-1 px-2 text-sm"
                  data-yourir="navPage3"
                ></button>
                <button
                  className="inline-block rounded-sm border py-1 px-2 text-sm"
                  data-yourir="navPage4"
                ></button>
                <button
                  className="inline-block rounded-sm border py-1 px-2 text-sm"
                  data-yourir="navPage5"
                ></button>
                <button
                  className="inline-block rounded-sm border py-1 px-2 text-sm"
                  data-yourir="nextPage"
                >
                  &gt;
                </button>
                <button
                  className="inline-block rounded-sm border py-1 px-2 text-sm"
                  data-yourir="lastPage"
                >
                  &gt;&gt;
                </button>
              </div>
            </div>
            <div data-yourir="if isEmpty">No results</div>
          </div>
        </div>

        <div className={isActive === "NZX" ? "block" : "hidden"}>
          <div data-yourir="announcements pageSize=10 symbol=kmd.nzx notTypes=OFFER">
            <div className="space-x-1">
              <button
                className="inline-block rounded-sm border py-1 px-2 text-sm"
                data-yourir="firstYear"
              >
                &lt;&lt;
              </button>
              <button
                className="inline-block rounded-sm border py-1 px-2 text-sm"
                data-yourir="prevYear"
              >
                &lt;
              </button>
              <button
                className="inline-block rounded-sm border py-1 px-2 text-sm"
                data-yourir="navYear1"
              ></button>
              <button
                className="inline-block rounded-sm border py-1 px-2 text-sm"
                data-yourir="navYear2"
              ></button>
              <button
                className="inline-block rounded-sm border py-1 px-2 text-sm"
                data-yourir="navYear3"
              ></button>
              <button
                className="inline-block rounded-sm border py-1 px-2 text-sm"
                data-yourir="navYear4"
              ></button>
              <button
                className="inline-block rounded-sm border py-1 px-2 text-sm"
                data-yourir="navYear5"
              ></button>
              <button
                className="inline-block rounded-sm border py-1 px-2 text-sm"
                data-yourir="nextYear"
              >
                &gt;
              </button>
              <button
                className="inline-block rounded-sm border py-1 px-2 text-sm"
                data-yourir="lastYear"
              >
                &gt;&gt;
              </button>
            </div>
            <div className="mt-10" data-yourir="ifnot isEmpty">
              <ul className="space-y-4" data-yourir="items">
                <li>
                  <div className="mb-1" data-yourir="$cur.date"></div>
                  <div
                    className="hidden-link text-2xl font-semibold"
                    data-yourir="$cur.heading"
                  ></div>
                </li>
              </ul>
            </div>

            <div data-yourir="ifnot isEmpty">
              <div className="mt-10 space-x-1">
                <button
                  className="inline-block rounded-sm border py-1 px-2 text-sm"
                  data-yourir="firstPage"
                >
                  &lt;&lt;
                </button>
                <button
                  className="inline-block rounded-sm border py-1 px-2 text-sm"
                  data-yourir="prevPage"
                >
                  &lt;
                </button>
                <button
                  className="inline-block rounded-sm border py-1 px-2 text-sm"
                  data-yourir="navPage1"
                ></button>
                <button
                  className="inline-block rounded-sm border py-1 px-2 text-sm"
                  data-yourir="navPage2"
                ></button>
                <button
                  className="inline-block rounded-sm border py-1 px-2 text-sm"
                  data-yourir="navPage3"
                ></button>
                <button
                  className="inline-block rounded-sm border py-1 px-2 text-sm"
                  data-yourir="navPage4"
                ></button>
                <button
                  className="inline-block rounded-sm border py-1 px-2 text-sm"
                  data-yourir="navPage5"
                ></button>
                <button
                  className="inline-block rounded-sm border py-1 px-2 text-sm"
                  data-yourir="nextPage"
                >
                  &gt;
                </button>
                <button
                  className="inline-block rounded-sm border py-1 px-2 text-sm"
                  data-yourir="lastPage"
                >
                  &gt;&gt;
                </button>
              </div>
            </div>
            <div data-yourir="if isEmpty">No results</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AnnouncementsSection
