import React from "react"

import { useStaticQuery, graphql } from "gatsby"

import { format, parseISO } from "date-fns"

const KeyDatesSection = ({ fields }) => {
  const data = useStaticQuery(graphql`
    query KeyDatesSectionQuery {
      allPrismicKeyDates(sort: { fields: data___date, order: DESC }) {
        edges {
          node {
            id
            data {
              title
              date
            }
          }
        }
      }
    }
  `)

  const { title } = fields

  return (
    <div>
      {title && (
        <div className="mb-10">
          <h2 className="text-3xl font-bold uppercase tracking-wide">
            {title}
          </h2>
        </div>
      )}

      {data.allPrismicKeyDates.edges.length ? (
        <ul className="space-y-4">
          {data.allPrismicKeyDates.edges.map((item, index) => {
            const { title, date } = item.node.data

            if (!title || !date) return null

            const dateIso = parseISO(date)
            const formattedDate = format(dateIso, "dd MMMM yyyy")

            return (
              <li key={index}>
                <div className="mb-1">{formattedDate}</div>
                <h4 className="text-2xl font-semibold">{title}</h4>
              </li>
            )
          })}
        </ul>
      ) : (
        <p>Sorry, we can't find any key dates.</p>
      )}
    </div>
  )
}

export default KeyDatesSection
