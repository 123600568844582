import React from "react"
import { PrismicLink } from "@prismicio/react"

const EmbedsSection = ({ fields, items }) => {
  const { title } = fields

  if (!items.length) return null

  return (
    <div className="section">
      {title && (
        <div className="grid grid-cols-12 lg:gap-x-10">
          <div className="col-start-3 col-end-13 mb-12 lg:mb-14">
            <h2 className="max-w-sm text-3xl font-bold uppercase tracking-wide">
              {title}
            </h2>
          </div>
        </div>
      )}

      <div className="grid grid-cols-12 lg:gap-x-10">
        <div className="col-start-3 col-end-13">
          <div className="grid grid-cols-10 gap-y-10 lg:gap-y-0 lg:gap-x-10">
            {items.map((item, index) => {
              const { cta_text, cta_link, embed } = item

              if (!embed) return null

              return (
                <div className="col-span-9 lg:col-span-4" key={index}>
                  <div
                    className="aspect-w-16 aspect-h-9"
                    dangerouslySetInnerHTML={{ __html: embed.html }}
                  />
                  {cta_text && cta_link && (
                    <PrismicLink
                      className="link link--black mt-6 text-lg font-semibold"
                      field={cta_link}
                    >
                      {cta_text}
                    </PrismicLink>
                  )}
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}

export default EmbedsSection
