import React, { useEffect, useRef } from "react"

const JobAdderWidget = () => {
  useEffect(() => {
    window._jaJobsSettings = {
      key: "AU2_evvovwizcazurdzgirhupwiwdy",
      applicationFormSettings: {
        useExternalApplicationForm: true,
        showExternalApplicationFormInNewWindow: false,
      },
      jobDetailsSettings: {
        showClassifications: false,
        backLinkText: "Back",
      },
      jobListSettings: {
        jobsPerPage: 6,
        noJobsContent:
          "There are no jobs currently available, check back again soon!",
        showClassifications: false,
      },
    }

    const widgetScript = document.createElement("script")
    widgetScript.src = "https://apps.jobadder.com/widgets/v1/jobs.min.js"
    widgetScriptRef.current.replaceWith(widgetScript)
  })

  const widgetScriptRef = useRef()

  return (
    <>
      <div id="ja-jobs-widget"></div>
      <script ref={widgetScriptRef}></script>
    </>
  )
}

export default JobAdderWidget
