import React, { useState, useMemo, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { PrismicLink } from "@prismicio/react"
import { format, parseISO, getYear } from "date-fns"

const PresentationsSection = ({ fields }) => {
  const data = useStaticQuery(graphql`
    query PresentationsSectionQuery {
      presentations: allPrismicPresentations(
        sort: { fields: data___date, order: DESC }
      ) {
        nodes {
          id
          data {
            title
            date
            link {
              ...linkFragment
            }
          }
        }
      }
    }
  `)

  const { title } = fields
  const presentations = data.presentations.nodes

  const [filterYear, setFilterYear] = useState("")
  const [currentPresentations, setCurrentPresentations] = useState([])

  const validPresentations = useMemo(() => {
    if (!presentations.length) return []

    return presentations.filter(item => {
      const { title, date, link } = item.data
      if (title && date && link) return true
    })
  }, [])

  const filterYearOptions = useMemo(() => {
    if (!validPresentations.length) return []

    const years = []
    validPresentations.map(item => {
      const { date } = item.data

      const dateIso = parseISO(date)
      const year = getYear(dateIso)
      years.push(year)
    })

    const yearsUnique = [...new Set(years)]
    return yearsUnique
  }, [])

  useEffect(() => {
    setFilterYear(filterYearOptions.length ? filterYearOptions[0] : "")
    setCurrentPresentations(validPresentations)
  }, [])

  useEffect(() => {
    const filteredPresentations = validPresentations.filter(item => {
      const { date } = item.data
      const dateIso = parseISO(date)
      const year = getYear(dateIso)
      return year === filterYear
    })

    setCurrentPresentations(filteredPresentations)
  }, [filterYear])

  const handleFilterYearClick = year => {
    setFilterYear(year)
  }

  return (
    <div>
      {title && (
        <div className="mb-10">
          <h2 className="text-3xl font-bold uppercase tracking-wide">
            {title}
          </h2>
        </div>
      )}

      {validPresentations.length ? (
        <>
          {filterYearOptions.length && (
            <div className="mb-10 inline-block rounded-sm border">
              {filterYearOptions.map(year => {
                const isCurrent = year === filterYear

                return (
                  <button
                    className={`px-4 py-1 ${
                      !isCurrent
                        ? ""
                        : "cursor-default bg-night-black text-cloud-white"
                    }`}
                    onClick={
                      !isCurrent
                        ? handleFilterYearClick.bind(this, year)
                        : undefined
                    }
                  >
                    {year}
                  </button>
                )
              })}
            </div>
          )}
          <ul className="space-y-4">
            {currentPresentations.map((item, index) => {
              const { title, date, link } = item.data
              const dateIso = parseISO(date)
              const formattedDate = format(dateIso, "dd MMMM yyyy")

              return (
                <li className="" key={index}>
                  <PrismicLink
                    className="hidden-link inline-block"
                    field={link}
                    target="_blank"
                  >
                    <div className="mb-1">{formattedDate}</div>
                    <h4 className="text-2xl font-semibold">{title}</h4>
                  </PrismicLink>
                </li>
              )
            })}
          </ul>
        </>
      ) : (
        <div>Sorry, we can't find any presentations.</div>
      )}
    </div>
  )
}

export default PresentationsSection
