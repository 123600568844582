import React from "react"

import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { PrismicLink } from "@prismicio/react"

const SustainabilityReportsSection = ({ fields }) => {
  const data = useStaticQuery(graphql`
    query SustainabilityReportsSectionQuery {
      allPrismicReports(
        sort: { fields: data___date, order: DESC }
        filter: { data: { is_sustainability_report: { eq: true } } }
      ) {
        edges {
          node {
            id
            data {
              is_sustainability_report
              is_featured
              title
              date
              image {
                gatsbyImageData
                alt
              }
              link {
                ...linkFragment
              }
            }
          }
        }
      }
    }
  `)

  const currentReports = data.allPrismicReports.edges.filter(
    item => item.node.data.is_featured === true
  )

  const previousReports = data.allPrismicReports.edges.filter(
    item => item.node.data.is_featured === false
  )

  const { title } = fields

  return (
    <div>
      <div className="section">
        {title && (
          <div className="grid grid-cols-12 lg:gap-x-10">
            <div className="col-start-1 col-end-13 mb-12 lg:col-start-2 lg:mb-14">
              <h2 className="max-w-sm text-3xl font-bold uppercase tracking-wide">
                {title}
              </h2>
            </div>
          </div>
        )}

        <div className="grid grid-cols-12 lg:gap-x-10">
          <div className="col-start-3 col-end-13">
            <div className="grid grid-cols-10 gap-y-10 lg:gap-x-10">
              {currentReports.map((item, index) => {
                const { title, link, image } = item.node.data

                if (!title || !link || !image) return null

                return (
                  <div className="col-span-10 lg:col-span-3" key={index}>
                    <PrismicLink field={link} target="_blank">
                      <GatsbyImage
                        className="hover:opacity-90 focus:opacity-90"
                        image={getImage(image.gatsbyImageData)}
                        aspectRatio={2 / 3}
                        alt={image.alt || title}
                      />
                    </PrismicLink>
                    <div className="mt-4">
                      <h4 className="text-xl font-semibold">{title}</h4>
                      <PrismicLink
                        className="button button--small button--black mt-4"
                        field={link}
                        target="_blank"
                      >
                        Download
                      </PrismicLink>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>

      {previousReports.length && (
        <div className="section pt-0">
          <div className="grid grid-cols-12 lg:gap-x-10">
            <div className="col-start-1 col-end-13 mb-12 lg:col-start-2 lg:mb-14">
              <h2 className="max-w-sm text-3xl font-bold uppercase tracking-wide">
                Previous Reports
              </h2>
            </div>
          </div>

          <div className="grid grid-cols-12 lg:gap-x-10">
            <div className="col-start-3 col-end-13">
              <ul className="grid grid-cols-10 gap-y-10 lg:gap-x-10">
                {previousReports.map((item, index) => {
                  const { title, link } = item.node.data

                  if (!title || !link) return null

                  return (
                    <li className="col-span-10 lg:col-span-3" key={index}>
                      <h4 className="text-2xl font-semibold lg:max-w-sm">
                        {title}
                      </h4>
                      <PrismicLink
                        className="button button--small button--black mt-4"
                        field={link}
                        target="_blank"
                      >
                        Download
                      </PrismicLink>
                    </li>
                  )
                })}
              </ul>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default SustainabilityReportsSection
