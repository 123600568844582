import React from "react"

import BrandsSection from "./BrandsSection"
import CTACardsSection from "./CTACardsSection"
import ProfilesSection from "./ProfilesSection"
import ReportsSection from "./ReportsSection"
import SustainabilityReportsSection from "./SustainabilityReportsSection"
import KeyDatesSection from "./KeyDatesSection"
import PresentationsSection from "./PresentationsSection"
import AnnouncementsSection from "./AnnouncementsSection"
import EmbedsSection from "./EmbedsSection"
import RichTextSection from "./RichTextSection"
import InvestorCentreSummarySection from "./InvestorCentreSummarySection"
import AnnouncementsSummarySection from "./AnnouncementsSummarySection"
import SubscribeSection from "./SubscribeSection"
import JobAdderSection from "./JobAdderSection"
import CodeSection from "./CodeSection"

const typeComponentMap = {
  brands_section: BrandsSection,
  cta_cards_section: CTACardsSection,
  profiles_section: ProfilesSection,
  reports_section: ReportsSection,
  sustainability_reports_section: SustainabilityReportsSection,
  key_dates_section: KeyDatesSection,
  presentations_section: PresentationsSection,
  announcements_section: AnnouncementsSection,
  embeds_section: EmbedsSection,
  code_section: CodeSection,
  rich_text_section: RichTextSection,
  investor_centre_summary_section: InvestorCentreSummarySection,
  announcements_summary_section: AnnouncementsSummarySection,
  subscribe_section: SubscribeSection,
  job_adder_section: JobAdderSection,
}

const FlexibleContent = ({ ...props }) => {
  const { slice_type } = props

  console.log(slice_type)

  const Component = typeComponentMap[slice_type]

  if (!slice_type || !Component) return null

  return <Component {...props} />
}

export default FlexibleContent
