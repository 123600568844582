import React, { useState } from "react"

const TAG_DEFAULT = "div"
const CHILD_TYPE_TOGGLE = "Toggle"
const CHILD_TYPE_CONTENT = "Content"

const AccordionItem = ({ children, ...props }) => {
  const [isOpen, setIsOpen] = useState(false)
  const Tag = TAG_DEFAULT

  const handleToggleClick = () => setIsOpen(prevState => !prevState)

  if (!children) return null

  return (
    <Tag {...props}>
      {children.map((child, index) => {
        const type = child.type
        let props = { key: index }

        if (type === AccordionItem[CHILD_TYPE_TOGGLE]) {
          props = { ...props, isOpen, onClick: handleToggleClick }
        }

        if (type === AccordionItem[CHILD_TYPE_CONTENT]) {
          props = { ...props, isOpen }
        }

        return React.cloneElement(child, props)
      })}
    </Tag>
  )
}

AccordionItem[CHILD_TYPE_TOGGLE] = ({ tag = TAG_DEFAULT, ...props }) => {
  const newProps = (({ tag, isOpen, ...props }) => props)(props)
  return React.createElement(tag, newProps, newProps.children)
}

AccordionItem[CHILD_TYPE_CONTENT] = ({
  tag = TAG_DEFAULT,
  isOpen,
  ...props
}) => {
  const newProps = (({ tag, isOpen, ...props }) => props)(props)
  if (!isOpen) newProps.className = [newProps.className, "hidden"].join(" ")
  return React.createElement(tag, newProps, newProps.children)
}

export default AccordionItem
