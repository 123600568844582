import React from "react"

const SubscribeSection = ({ fields }) => {
  const { title } = fields

  return (
    <div>
      {title && (
        <div className="mb-10">
          <h2 className="text-3xl font-bold uppercase tracking-wide">
            {title}
          </h2>
        </div>
      )}

      <form className="max-w-lg" data-yourir="mailList">
        <div data-yourir="result" style={{ display: "none" }}></div>
        <input
          className="form-input border"
          type="text"
          data-yourir="emailAddress"
          placeholder="email@example.org"
        />
        <input
          className="button button--black w-full"
          type="button"
          data-yourir="subscribe"
          value="Subscribe"
        />
      </form>
    </div>
  )
}

export default SubscribeSection
