import React, { useState } from "react"

const AnnouncementsSummarySection = ({ fields }) => {
  const { title } = fields

  const [isActive, setActive] = useState("ASX")

  const toggleSymbol = symbol => {
    setActive(symbol)
  }

  return (
    <div>
      {title && (
        <div className="mb-10">
          <h2 className="text-3xl font-bold uppercase tracking-wide">
            {title}
          </h2>
        </div>
      )}
      <div>
        <div className="mb-10 inline-block rounded-sm border">
          <button
            className={`border-r px-4 py-1 ${
              isActive === "ASX" ? "bg-night-black text-cloud-white" : ""
            }`}
            onClick={() => toggleSymbol("ASX")}
          >
            ASX
          </button>
          <button
            className={`px-4 py-1 ${
              isActive === "NZX" ? "bg-night-black text-cloud-white" : ""
            }`}
            onClick={() => toggleSymbol("NZX")}
          >
            NZX
          </button>
        </div>

        <div className={isActive === "ASX" ? "block" : "hidden"}>
          <div data-yourir="announcements pageSize=4 symbol=kmd.asx notTypes=03026">
            <div data-yourir="ifnot isEmpty">
              <ul className="space-y-4" data-yourir="items">
                <li>
                  <div className="mb-1" data-yourir="$cur.date"></div>
                  <div
                    className="hidden-link text-2xl font-semibold"
                    data-yourir="$cur.heading"
                  ></div>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className={isActive === "NZX" ? "block" : "hidden"}>
          <div data-yourir="announcements pageSize=4 symbol=kmd.nzx notTypes=OFFER">
            <div data-yourir="ifnot isEmpty">
              <ul className="space-y-4" data-yourir="items">
                <li>
                  <div className="mb-1" data-yourir="$cur.date"></div>
                  <div
                    className="hidden-link text-2xl font-semibold"
                    data-yourir="$cur.heading"
                  ></div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AnnouncementsSummarySection
