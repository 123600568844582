import React from "react"

import { PrismicRichText, PrismicLink } from "@prismicio/react"

const RichTextSection = ({ fields, items }) => {
  const { title, content } = fields

  return (
    <div>
      {title && (
        <div className="mb-10">
          <h2 className="text-3xl font-bold uppercase tracking-wide">
            {title}
          </h2>
        </div>
      )}

      {(content || items.length) && (
        <div className="rte">
          <PrismicRichText field={content.richText} />

          {items.length && (
            <ul>
              {items.map((item, index) => {
                const { file_title, file_link } = item

                if (!file_title || !file_link) return null

                return (
                  <li>
                    <PrismicLink key={index} field={file_link} target="_blank">
                      {file_title}
                    </PrismicLink>
                  </li>
                )
              })}
            </ul>
          )}
        </div>
      )}
    </div>
  )
}

export default RichTextSection
