import React, { useState } from "react"

import { useStaticQuery, graphql } from "gatsby"

import { format, parseISO } from "date-fns"

const InvestorCentreSummarySection = ({ fields }) => {
  const data = useStaticQuery(graphql`
    query InvestorCentreSummarySectionQuery {
      allPrismicKeyDates(sort: { fields: data___date, order: DESC }) {
        edges {
          node {
            id
            data {
              title
              date
            }
          }
        }
      }
    }
  `)

  // const { title } = fields

  const [isActive, setActive] = useState("ASX")

  const toggleSymbol = symbol => {
    setActive(symbol)
  }

  return (
    <div>
      <div className="section pb-0">
        <h2 className="mb-10 text-3xl font-bold uppercase tracking-wide">
          Latest announcements
        </h2>

        <div className="mb-10 inline-block rounded-sm border">
          <button
            className={`border-r px-4 py-1 ${
              isActive === "ASX" ? "bg-night-black text-cloud-white" : ""
            }`}
            onClick={() => toggleSymbol("ASX")}
          >
            ASX
          </button>
          <button
            className={`px-4 py-1 ${
              isActive === "NZX" ? "bg-night-black text-cloud-white" : ""
            }`}
            onClick={() => toggleSymbol("NZX")}
          >
            NZX
          </button>
        </div>

        <div className={isActive === "ASX" ? "block" : "hidden"}>
          <div data-yourir="announcements pageSize=3 symbol=kmd.asx notTypes=03026">
            <div data-yourir="ifnot isEmpty">
              <ul
                className="grid grid-cols-12 gap-y-10 lg:gap-x-10"
                data-yourir="items"
              >
                <li className="col-span-12 lg:col-span-4">
                  <div className="mb-1" data-yourir="$cur.date"></div>
                  <div
                    className="hidden-link text-2xl font-semibold lg:max-w-sm"
                    data-yourir="$cur.heading"
                  ></div>
                  {/* <div className="link link--black mt-6 text-lg font-semibold">
                  Read announcement
                </div> */}
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className={isActive === "NZX" ? "block" : "hidden"}>
          <div data-yourir="announcements pageSize=3 symbol=kmd.nzx notTypes=OFFER">
            <div data-yourir="ifnot isEmpty">
              <ul
                className="grid grid-cols-12 gap-y-10 gap-x-10"
                data-yourir="items"
              >
                <li className="col-span-12 lg:col-span-4">
                  <div className="mb-1" data-yourir="$cur.date"></div>
                  <div
                    className="hidden-link text-2xl font-semibold lg:max-w-sm"
                    data-yourir="$cur.heading"
                  ></div>
                  {/* <div className="link link--black mt-6 text-lg font-semibold">
                  Read announcement
                </div> */}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="section">
        <h2 className="mb-10 text-3xl font-bold uppercase tracking-wide">
          Key Dates
        </h2>

        {data.allPrismicKeyDates.edges.length ? (
          <ul className="grid grid-cols-12 gap-y-10 lg:gap-x-10">
            {data.allPrismicKeyDates.edges.map((item, index) => {
              const { title, date } = item.node.data

              if (!title || !date) return null

              const dateIso = parseISO(date)
              const formattedDate = format(dateIso, "dd MMMM yyyy")

              return (
                <li className="col-span-12 lg:col-span-4" key={index}>
                  <div className="mb-1">{formattedDate}</div>
                  <h4 className="text-2xl font-semibold lg:max-w-sm">
                    {title}
                  </h4>
                </li>
              )
            })}
          </ul>
        ) : (
          <p>Sorry, we can't find any key dates.</p>
        )}
      </div>
    </div>
  )
}

export default InvestorCentreSummarySection
