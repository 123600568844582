import React from "react"

import JobAdderWidget from "../JobAdderWidget"

const JobAdderSection = () => {
  return (
    <section className="section bg-night-black text-cloud-white">
      <div className="grid grid-cols-12 space-y-10 lg:gap-x-10">
        <div className="col-start-1 col-end-11 lg:col-start-3 lg:col-end-9">
          <h2 class="mb-12 max-w-sm text-3xl font-bold uppercase tracking-wide lg:mb-14">
            Available Positions
          </h2>

          <JobAdderWidget />
        </div>
      </div>
    </section>
  )
}

export default JobAdderSection
